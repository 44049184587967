// TODO REB-95 Clean up components in web2
import {
  BodySmall as BodySmallImport,
  Button as ButtonImport,
  buttonSizes,
  buttonTypes,
  HeaderExtraSmall as HeaderExtraSmallImport,
  surfaceColors
} from '@animoto/components';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import PropTypes from 'prop-types';
import React from 'react';

import {
  container,
  desktop,
  mobile,
  text,
  textWrapper
} from './Banner.module.css';

export default function Banner({
  hasVideoCompleted,
  onClick
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const Button = ButtonImport(isRebrand);
  const BodySmall = BodySmallImport(isRebrand);
  const HeaderExtraSmall = HeaderExtraSmallImport(isRebrand);

  const trackClick = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'banner',
      action  : 'click'
    });
  };

  const onClickButton = () => {
    trackClick();
    onClick();
  };

  function getButtonType() {
    if (isRebrand) {
      return buttonTypes.SECONDARY;
    }
    return hasVideoCompleted ? buttonTypes.PRIMARY : buttonTypes.SECONDARY;
  }

  const getMobileBanner = () => (
    <div className={mobile}>
      <div className={textWrapper}>
        <HeaderExtraSmall isInverted={!isRebrand}>Animoto</HeaderExtraSmall>
        <BodySmall isInverted={!isRebrand}>Make your own video in minutes</BodySmall>
      </div>
      <Button
        onClick={onClickButton}
        size={buttonSizes.SMALL}
        surfaceColor={isRebrand ? undefined : surfaceColors.ACCENT}
        type={getButtonType()}
      >
        Start Free
      </Button>
    </div>
  );

  const getDesktopBanner = () => (
    <div className={desktop}>
      <HeaderExtraSmall className={text} isInverted={!isRebrand}>Create a video in minutes with Animoto.<br /> No credit card required. </HeaderExtraSmall>
      <Button
        onClick={onClickButton}
        size={buttonSizes.SMALL}
        surfaceColor={isRebrand ? undefined : surfaceColors.ACCENT}
        type={getButtonType()}
      >
        Get Started Free
      </Button>
    </div>
  );

  return (
    <div className={container}>
      {getMobileBanner()}
      {getDesktopBanner()}
    </div>
  );
}

Banner.propTypes = {
  hasVideoCompleted : PropTypes.bool.isRequired,
  onClick           : PropTypes.func.isRequired
};
