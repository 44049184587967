import { trackFeatureUsage } from '@animoto/utils/analytics';
import splunk from '@animoto/utils/splunk';

function trackPlayerEvent(event) {
  trackFeatureUsage({
    scope   : 'visitor-play-page',
    feature : 'player',
    action  : `${event}:final-player`
  });
}

export default function setupTracking(videoPlayer) {
  trackFeatureUsage({
    scope   : 'visitor-play-page',
    feature : 'player',
    action  : 'view:final-player'
  });

  videoPlayer.on('error', (event) => {
    splunk({
      kind  : 'player_error',
      error : `visitor-play-page: ${event.message}`
    });
  });

  videoPlayer.once('ready', () => {
    trackPlayerEvent('initialized');
  });

  videoPlayer.once('play', () => {
    trackPlayerEvent('started');
  });

  videoPlayer.once('ended', () => {
    trackPlayerEvent('ended');
  });
}
