import '@animoto/common-styles/dist/animoto.webfonts.css';

import { initializeSentry } from '@animoto/utils/sentry';
import React from 'react';
import ReactDOM from 'react-dom';

import { setToken } from '../../utils/appservice';
import {
  isRegisteredUser as getIsRegisteredUser,
  setUserAttributes
} from '../../utils/userWrapper';
import PlayApp from './components/PlayApp';

export default function bootstrap(elementId, settings) {
  initializeSentry();
  setUserAttributes(settings.currentUserAttributes);
  const isRegisteredUser = getIsRegisteredUser();

  if (isRegisteredUser) {
    setToken();
  }

  ReactDOM.render(
    <PlayApp {...settings.params} isRegisteredUser={isRegisteredUser} />,
    document.getElementById(elementId)
  );
}
