import {
  api,
  httpMethods,
  refreshClientToken
} from '@animoto/appservice-client';
import Cookies from 'js-cookie';
import delay from 'lodash-es/delay';

import resolutions from '../../pages/share/configs/enums/resolutions';

const pollingInterval = {
  [resolutions.LOWEST]  : 5000,
  [resolutions.LOW]     : 10000,
  [resolutions.DEFAULT] : 15000,
  [resolutions.HIGH]    : 20000
};

const getPollingInterval = (resolution) => pollingInterval[resolution] || pollingInterval[resolutions.LOWEST];

export default async function pollRenderJob(pollingUrl, onComplete, onError) {
  let token = Cookies.get('user_token') || Cookies.get('client_token');
  if (!token) {
    const response = await refreshClientToken();
    const jsonResponse = await response.json();
    token = jsonResponse.token;
    Cookies.set('client_token', token);
  }

  try {
    const apiResponse = await api({
      path   : pollingUrl,
      token,
      method : httpMethods.GET
    });

    if (apiResponse.ok) {
      const response = await apiResponse.json();
      const renderingData = response.response.payload.rendering_job;

      if (renderingData.state === 'completed') {
        onComplete(renderingData);
      } else if (renderingData.state === 'failed') {
        onError();
      } else {
        delay(pollRenderJob, getPollingInterval(renderingData.resolution), pollingUrl, onComplete, onError);
      }
    } else {
      onError();
    }
  } catch (e) {
    onError();
  }
}
