import noop from 'lodash-es/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Player extends PureComponent {
  constructor(props) {
    super(props);
    this.createPlayer = this.createPlayer.bind(this);
  }

  /**
   * React lifecycle method. Creates the video on mount.
   *
   * @method componentDidMount
   *
   */
  componentDidMount() {
    this.createPlayer();
  }

  componentWillUnmount() {
    if (this.currentPlayer) {
      this.currentPlayer.destroy();
    }
  }

  /**
   * Called after video modal is on the DOM. Creates a new video.
   *
   * @method createPlayer
   *
   */
  createPlayer() {
    const {
      onCreatePlayer,
      onCompleted,
      onStarted,
      settings
    } = this.props;

    this.currentPlayer = new window.Player('js-video-player', settings);
    this.currentPlayer.on('play', onStarted);
    this.currentPlayer.on('ended', onCompleted);
    onCreatePlayer(this.currentPlayer);
  }

  render() {
    return (
      <video id="js-video-player" />
    );
  }
}

Player.propTypes = {
  onCompleted    : PropTypes.func,
  onCreatePlayer : PropTypes.func,
  onStarted      : PropTypes.func,
  settings       : PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

Player.defaultProps = {
  onCreatePlayer : noop,
  onCompleted    : noop,
  onStarted      : noop
};
